import { useCallback, useMemo, useState, type ComponentType } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AssetType, Currency, LocationType } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchAttachmentIv, fetchExchangeRates, fetchThumbnailUrl } from '@/api/CommonService'
import { fetchPropertyAssetsBreakdown, fetchPropertyInfo, propertyQuery } from '@/api/PropertyService'
import { placeholderImageMap } from '@/constants/assets'
import type { LatLng, LocationSummary } from '@/types/common'
import { formatNumber } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { useAuthStore } from '@/store/authStore'
import { useMap } from '@/store/map'
import TruncatedText from '@/components/base/TruncatedText'
import { Carousel, CarouselItem } from '@/components/Carousel'
import { EncryptedImage } from '@/components/EncryptedImage'
import { BelongingIcon, BottlesIcon, LocationIcon } from '@/components/icon'

export interface MapWidgetCardProps extends LocationSummary {
  onFocus?: () => void
  onClick?: () => void
  currency?: Currency
}

const responsive = [
  { breakpoint: 1280, count: 3 },
  { breakpoint: 768, count: 2 },
  { breakpoint: 576, count: 1 },
  { breakpoint: 0, count: 1 }
]

interface MapWidgetProps {
  locations: LocationSummary[]
  renderItem?: ComponentType<MapWidgetCardProps>
  placeholder?: { title: string; subText: string }
  currency?: Currency
  onCardClick?: (id: string) => void
}

export default function MapWidget({ locations, renderItem, placeholder, currency, onCardClick }: MapWidgetProps) {
  const { isLoaded } = useMap()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const hasLocations = locations.length > 0
  // there should be a default center, otherwise the map will not be displayed
  const center = locations[0]?.item.latLng ?? { lat: 0, lng: 0 }
  const sortedLocations = useMemo(
    () => locations.sort((a, b) => b.info?.value.value ?? 0 - (a.info?.value.value ?? 0)),
    [locations]
  )

  const handleOnLoad = (map: google.maps.Map) => setMap(map)
  const handleOnUnmount = () => setMap(null)
  const handleOnFocus = (latLng: LatLng | undefined) => latLng && map?.panTo(latLng)

  return hasLocations ? (
    <div className={'relative h-96 w-full bg-gray-200'}>
      {isLoaded && (
        <GoogleMap
          onLoad={handleOnLoad}
          onUnmount={handleOnUnmount}
          mapContainerClassName={'h-full w-full'}
          options={{
            center,
            fullscreenControl: false,
            zoomControlOptions: { position: 3 },
            streetViewControl: false,
            scrollwheel: false,
            mapTypeControl: false,
            zoom: 4,
            minZoom: 3
          }}
        >
          {sortedLocations.map((loc, index) => {
            const latLng = loc.item.latLng
            return latLng && <MarkerF key={index} position={latLng} onClick={() => handleOnFocus(latLng)} />
          })}
        </GoogleMap>
      )}
      {renderItem && (
        <div className={'absolute inset-x-4 bottom-4 mx-4'}>
          <Carousel responsive={responsive}>
            {sortedLocations.map((loc, index) => {
              const latLng = loc.item.latLng
              const ItemCard = renderItem
              return (
                <CarouselItem key={index}>
                  <ItemCard
                    key={index}
                    onFocus={() => handleOnFocus(latLng)}
                    currency={currency}
                    onClick={() => onCardClick?.(loc.item.id)}
                    {...loc}
                  />
                </CarouselItem>
              )
            })}
          </Carousel>
        </div>
      )}
    </div>
  ) : (
    <div className={'relative h-96 w-full bg-gray-200'}>
      <Image className={'object-cover'} src={'/images/properties/mapPlaceholder.jpg'} alt={'Empty Map'} fill={true} />
      <div className={'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white '}>
        <div className={'text-center text-2xl font-bold'}>{placeholder?.title}</div>
        <div className={'text-center'}>{placeholder?.subText}</div>
      </div>
    </div>
  )
}

const placeholder = placeholderImageMap['Property']

export function PropertyCard({ item, onFocus, currency }: MapWidgetCardProps) {
  const id = item.id
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: property } = useSWR(id && [propertyQuery.info, id], fetchPropertyInfo(database!))
  const { data: assetsBreakdown } = useSWR(
    id && [propertyQuery.assetsBreakdown, id],
    fetchPropertyAssetsBreakdown(database!)
  )
  const { data: mainImageUrl } = useSWR(
    property?.mainImage && [commonQuery.thumbnailUrl, item.id, property.mainImage, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    property?.mainImage && [commonQuery.attachmentIV, AssetType.Property, item.id, property.mainImage],
    fetchAttachmentIv(database!)
  )
  const targetCurrency = currency ?? preferences.baseCurrency!
  const { data: baseToTargetRate } = useSWR(
    [commonQuery.exchangeRates, preferences.baseCurrency!, targetCurrency],
    fetchExchangeRates(database!)
  )
  const propertyCurrency = property?.value?.currency ?? preferences.baseCurrency!
  const { data: propertyToTargetRate } = useSWR(
    [commonQuery.exchangeRates, propertyCurrency, targetCurrency],
    fetchExchangeRates(database!)
  )

  const formatCurrencyValue = useCallback(
    (value: number, rate: number = 1) => `${targetCurrency} ${formatNumber(value * rate, preferences.numberFormat)}`,
    [preferences, targetCurrency]
  )

  const propertyValue = property?.value?.value
    ? formatCurrencyValue(property.value.value, propertyToTargetRate?.rate ?? 1)
    : '-'

  const assetsValue = useMemo(() => {
    if (!assetsBreakdown) return formatCurrencyValue(0)
    const netValue =
      assetsBreakdown.assets.reduce((acc, { value }) => acc + value.value, 0) -
      assetsBreakdown.liabilities.reduce((acc, { value }) => acc + value.value, 0)
    return formatCurrencyValue(netValue, baseToTargetRate?.rate ?? 1)
  }, [assetsBreakdown, baseToTargetRate, formatCurrencyValue])

  return (
    <Link
      className={'flex h-32 shrink-0 rounded bg-white text-text transition-colors hover:bg-gray-200'}
      href={{ pathname: '/properties/summary/info/', query: { id } }}
      onMouseEnter={onFocus}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        <EncryptedImage
          className={'object-cover'}
          src={mainImageUrl}
          base64IV={mainImageIv}
          alt={item.id}
          fill={true}
          placeholder={placeholder}
        />
      </div>
      {property && (
        <div className={'flex w-full flex-col items-start justify-between border-l p-2'}>
          <div className={'flex w-full flex-wrap items-center justify-between text-sm font-medium'}>
            <p>{property.name}</p>
            <p className={'text-primary'}>{t(`properties:PropertyTypeOptions.${property.subtype}`)}</p>
          </div>
          <div className={'flex w-full flex-col gap-y-1 text-xs'}>
            <div className={'flex items-center justify-between gap-x-1'}>
              <span className={'shrink-0 text-[#6D83AC]'}>{t('properties:PropertyValue')}</span>
              <TruncatedText as={'span'} className={'font-medium'}>
                {propertyValue}
              </TruncatedText>
            </div>
            <div className={'flex items-center justify-between gap-x-1'}>
              <span className={'shrink-0 text-[#6D83AC]'}>{t('properties:AssetValue')}</span>
              <TruncatedText as={'span'} className={'font-medium'}>
                {assetsValue}
              </TruncatedText>
            </div>
          </div>
        </div>
      )}
    </Link>
  )
}

export function WineCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )
  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat)
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div
      className={'flex h-32 cursor-pointer overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'}
      onFocus={onFocus}
      onClick={onClick}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={'Location'}
            fill={true}
            placeholder={placeholderImageMap['Property']}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1  text-xs'}>{info.address}</TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BottlesIcon size={16} />
            <span className={'text-xs'}>{t('collectables:BottlesWithNumber', { number })}</span>
          </div>
          <TruncatedText as={'span'} className={' text-sm'}>{`${currency} ${value}`}</TruncatedText>
        </div>
      </div>
    </div>
  )
}

export function BelongingCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )

  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat)
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div className={'flex h-32 overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'} onFocus={onFocus}>
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={info.name}
            fill={true}
            placeholder={placeholderImageMap['Property']}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1 text-xs'}>{info.address}</TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BelongingIcon size={16} />
            <span className={'text-xs'}>{`${number} ` + t('Assets')}</span>
          </div>
          <TruncatedText as={'span'} className={'text-sm'}>{`${currency} ${value}`}</TruncatedText>
        </div>
      </div>
    </div>
  )
}

export function OtherCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )

  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat)
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div
      className={'flex h-32 overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'}
      onFocus={onFocus}
      onClick={onClick}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={info.name}
            fill={true}
            placeholder={placeholderImageMap['Property']}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1 text-xs'}>{info.address}</TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BelongingIcon size={16} />
            <span className={'text-xs'}>{`${number} ` + t('Assets')}</span>
          </div>
          <TruncatedText as={'span'} className={'text-sm'}>{`${currency} ${value}`}</TruncatedText>
        </div>
      </div>
    </div>
  )
}
